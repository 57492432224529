import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0eca37cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tenders__search js-tender-search is-main-search" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.searchTender && _ctx.searchTender(...args)), ["prevent"]))
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: _ctx.$t('search.placeholder'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event))
      }, null, 8 /* PROPS */, _hoisted_2), [
        [_vModelText, _ctx.searchQuery]
      ]),
      _createElementVNode("input", {
        type: "submit",
        value: _ctx.$t('search.btn'),
        class: "btn btn-blue hide-mobile"
      }, null, 8 /* PROPS */, _hoisted_3)
    ], 32 /* HYDRATE_EVENTS */)
  ]))
}
import { tendersClient } from "@/api/tendersClient";
import { IFilterOptions, IFilters } from "@/interfaces/search";
import { IFiltersState, IRootState } from "@/interfaces/state";
import { ActionContext, Module } from "vuex";
import router from "@/router";
import { filterEvents, trackEvent } from "@/helpers/AmplitudeHelper";
import { typeRouteTranslator, regionRouteTranslator, categoryRouteTranslator, sourceValueToName } from "@/helpers/APIHelper";

const filtersModule: Module<IFiltersState, IRootState> = {
  state(): IFiltersState {
    return {
      filterOptions: {
        countries: [],
        regions: [],
        types: [],
        sources: [],
        categories: [],
      },
      filters: {},
      query: '',
      page: 1,
      filterUpdated: false,
      canSubscribe: false,
      filtersApplied: false,
      categoryDict: {},
      categoryOption: '',
      contentFilter: {},
    };
  },
  getters: {
    filterOptions(state: IFiltersState) {
      return state.filterOptions;
    },
    filters(state: IFiltersState) {
      return state.filters;
    },
    query(state: IFiltersState) {
      return state.query;
    },
    page(state: IFiltersState) {
      return state.page;
    },
    filterUpdated(state: IFiltersState) {
      return state.filterUpdated
    },
    canSubscribe(state: IFiltersState) {
      return state.canSubscribe
    },
    filtersApplied: (state: IFiltersState) => (currentRouteName: string) => {
      return (state.filtersApplied && router.currentRoute.value.name === currentRouteName)
    },
    categoryDict(state: IFiltersState) {
      return state.categoryDict
    },
    categoryOption(state: IFiltersState) {
      return state.categoryOption
    },
    contentFilter(state: IFiltersState) {
      return state.contentFilter
    }
  },
  mutations: {
    setFilterOptions(state: IFiltersState, payload: IFilterOptions) {
      state.filterOptions = {
        countries: payload.countries,
        regions: payload.regions,
        types: payload.types,
        sources: payload.sources,
        categories: payload.categories,
      };
    },
    setFilter<K extends keyof IFilters>(
      state: IFiltersState, 
      payload: { key: K; value: IFilters[K] }
    ) {
      if (payload.value === null || payload.value === undefined) {
        delete state.filters[payload.key];
      } else {
        if (payload.value instanceof Date) {
          state.filters[payload.key] = new Date(Date.UTC(payload.value.getFullYear(), payload.value.getMonth(), payload.value.getDate())) as IFilters[K];
        } else {
          state.filters[payload.key] = payload.value;
        }
      }
    },
    resetFilters(state: IFiltersState) {
      state.filters = {}
      state.categoryOption = ''
    },
    setQuery(state: IFiltersState, payload: { query: string }) {
      state.query = payload.query
    },
    setPage(state: IFiltersState, payload: { page: number }) {
      state.page = payload.page
    },
    setFilterUpdated(state: IFiltersState, payload: { updated: boolean }) {
      state.filterUpdated = payload.updated
    },
    setCanSubscribe(state: IFiltersState, payload: { canSubscribe: boolean }) {
      state.canSubscribe = payload.canSubscribe
    },
    setFiltersApplied(state: IFiltersState, payload: { applied: boolean }) {
      state.filtersApplied = payload.applied
    },
    setCategoryDict(state: IFiltersState, payload: object) {
      state.categoryDict = payload
    },
    setCategoryOption(state: IFiltersState, payload: string) {
      state.categoryOption = payload
    },
    setContentFilter(state: IFiltersState, payload: { key: string; value: string }) {
      state.contentFilter[payload.key] = payload.value
    }
  },
  actions: {
    async getFilterOptions(
      context: ActionContext<IFiltersState, IFiltersState>,
      payload: { lang: string },
    ) {
      const filterOptions = await tendersClient.getFilterOptions();
      const categories = await tendersClient.getCategories(payload.lang);
      if (filterOptions && categories) {
        filterOptions['categories'] = categories;
      }
      context.commit("setFilterOptions", filterOptions);

      if (context.getters.categoryOption !== '') {
        const chosenCategoryCode = context.getters.categoryOption.slice(0, 2)
        if (Object.keys(context.getters.categoryDict).length === 0) {
          await context.dispatch('buildCategoryDict')
        }
        context.commit(
          "setCategoryOption", 
          `${chosenCategoryCode} ${context.getters.categoryDict[chosenCategoryCode]}`
        )
      }
    },
    updateFilter<K extends keyof IFilters>(
      context: ActionContext<IFiltersState, IFiltersState>, 
      payload: { key: K; value: IFilters[K] }
    ) {
      context.commit('setFilter', payload);
      context.commit('setFilterUpdated', { updated: true })
    },
    resetFilters(
      context: ActionContext<IFiltersState, IFiltersState>,
      payload: { keepFilter?: { filterType: string }, shouldApplyFilter?: boolean } = {},
    ) {
      const { keepFilter = undefined } = payload;
      const { shouldApplyFilter = true } = payload;
      context.commit('resetFilters')
      if (keepFilter) {
        let setFilterPayload = {}
        switch (keepFilter.filterType) {
          case 'company':
            setFilterPayload = { key: 'company', value: context.getters.company.tin }
            break
          case 'source':
            setFilterPayload = { key: 'source', value: sourceValueToName[router.currentRoute.value.params.source as keyof typeof sourceValueToName] }
            break
          case 'type':
            setFilterPayload = {
              key: 'type', value: 
              typeRouteTranslator[router.currentRoute.value.params.type as keyof typeof typeRouteTranslator]
            }
            break
          case 'region':
            setFilterPayload = {
              key: 'region', 
              value: regionRouteTranslator[router.currentRoute.value.params.region as keyof typeof regionRouteTranslator]
            }
            break
          case 'category':
            setFilterPayload = {
              key: 'category',
              value: categoryRouteTranslator[router.currentRoute.value.params.category as keyof typeof categoryRouteTranslator]
            }
            break
        }
        context.commit('setFilter', setFilterPayload)
      }
      if (shouldApplyFilter) {
        context.dispatch('applyFilters')
      }
    },
    applyFilters(context: ActionContext<IFiltersState, IFiltersState>) {
      context.commit('setPage', { page: 1 })
      context.commit('setFilterUpdated', { updated: false })

      context.commit('setFiltersApplied', { applied: true })

      for (const key in context.getters.filters) {
        const filterKey = key as keyof IFilters;
        
        if (context.getters.filters[filterKey] !== null) {
          let eventProperties = undefined
          if (filterKey === 'region') {
            eventProperties = { region: context.getters.filters[filterKey] }
          } else if (filterKey === 'type') {
            eventProperties = { type: context.getters.filters[filterKey]}
          } else if (filterKey === 'company') {
            eventProperties = { company: context.getters.filters[filterKey]}
          } else if (filterKey === 'source') {
            eventProperties = { source: context.getters.filters[filterKey]}
          } else if (filterKey === 'category') {
            eventProperties = { category: context.getters.filters[filterKey]}
          } else if (filterKey === 'status' ) {
            eventProperties = { status: context.getters.filters[filterKey]}
          }
          trackEvent(filterEvents[filterKey], eventProperties)
        }
      }
    },
    setPage(context: ActionContext<IFiltersState, IFiltersState>, payload: { page: number }) {
      context.commit('setPage', payload)
    },
    setQuery(context: ActionContext<IFiltersState, IFiltersState>, payload: { query: string }) {
      context.commit('setQuery', payload)
    },
    setFiltersApplied(context: ActionContext<IFiltersState, IFiltersState>, payload: { applied: boolean }) {
      context.commit('setFiltersApplied', payload)
    },
    async buildCategoryDict(context: ActionContext<IFiltersState, IFiltersState>) {
      const lang = context.getters.getLanguage
      const categories = await tendersClient.getCategories(lang)

      let categoryDict: { [key: string]: string } = {};
      if (categories) {
        categoryDict = categories.reduce((acc, item) => {
          acc[item.code] = item.title;
          return acc;
        }, {} as { [key: string]: string });
      }
      context.commit('setCategoryDict', categoryDict)
    },
    updateCategoryOption(context: ActionContext<IFiltersState, IFiltersState>, payload: string) {
      context.commit('setCategoryOption', payload)
    },
    updateContentFilter<K extends keyof IFilters>(
      context: ActionContext<IFiltersState, IFiltersState>, 
      payload: { key: K; value: IFilters[K]} 
    ) {
      context.commit('setContentFilter', payload)
    },
  },
};

export default filtersModule;

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "field-group",
  "data-group": "1"
}
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "field__label" }
const _hoisted_4 = ["value", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("search.type")), 1 /* TEXT */),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (typeOption) => {
        return (_openBlock(), _createElementBlock("label", {
          class: "field__radio",
          key: typeOption
        }, [
          _createElementVNode("input", {
            style: {"cursor":"pointer"},
            type: "radio",
            name: "type-concurs",
            value: typeOption,
            checked: _ctx.checked(typeOption),
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setType && _ctx.setType(...args)))
          }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_4),
          _createTextVNode(" " + _toDisplayString(typeOption), 1 /* TEXT */)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}
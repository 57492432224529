import * as amplitude from '@amplitude/analytics-browser';
import { BaseEvent } from '@amplitude/analytics-types';

amplitude.init(
  process.env.VUE_APP_AMPLITUDE_PUBLIC_KEY, 
  localStorage.getItem('telegram_id') || undefined,
  { defaultTracking: false }
);

enum EventType {
  header_search = 'header_search',
  header_favorites = 'header_favorites',
  header_templates = 'header_templates',
  header_language = 'header_language',
  header_login = 'header_login',
  header_profile = 'header_profile',

  purchase_card_open = 'purchase_card_open',
  purchase_card_source = 'purchase_card_source',
  purchase_card_save = 'purchase_card_save',
  purchase_card_delete = 'purchase_card_delete',

  search_card_open = 'search_card_open',
  search_card_notifications_on = 'search_card_notifications_on',
  search_card_notifications_off = 'search_card_notifications_off',
  search_card_delete = 'search_card_delete',

  filters_region = 'filters_region',
  filters_type = 'filters_type',
  filters_price = 'filters_price',
  filters_publish_date = 'filters_publish_date',
  filters_end_date = 'filters_end_date',
  filters_company = 'filters_company',
  filters_source = 'filters_source',
  filters_category = 'filters_category',
  filters_status = 'filters_status',
  filters_reset = 'filters_reset',

  main_opened = 'main_opened',
  main_search = 'main_search',
  main_try_free = 'main_try_free',

  login_popup_opened = 'login_popup_opened',
  login_popup_telegram = 'login_popup_telegram',
  login_popup_sign_up = 'login_popup_sign_up',
  login_popup_sign_in = 'login_popup_sign_in',
  login_popup_close = 'login_popup_close',

  search_page_opened = 'search_page_opened',
  search_page_template_save = 'search_page_template_save',
  search_page_template_delete = 'search_page_template_delete',
  search_page_pagination = 'search_page_pagination',

  favourites_opened = 'favorites_opened',
  favourites_search = 'favorites_search',
  favourites_pagination = 'favorites_pagination',

  templates_opened = 'templates_opened',
  templates_search = 'templates_search',
  templates_pagination = 'templates_pagination',

  profile_opened = 'profile_opened',
  profile_sign_out = 'profile_sign_out',

  purchase_opened = 'purchase_opened',
  purchase_save = 'purchase_save',
  purchase_delete = 'purchase_delete',
  purchase_source_open = 'purchase_source_open',

  company_page_opened = 'company_page_opened',
  company_page_template_save = 'company_page_template_save',
  company_page_template_delete = 'company_page_template_delete',
  company_page_pagination = 'company_page_pagination',
}

const filterEvents = {
  region: EventType.filters_region,
  type: EventType.filters_type,
  priceFrom: EventType.filters_price,
  priceTo: EventType.filters_price,
  startDateFrom: EventType.filters_publish_date,
  startDateTo: EventType.filters_publish_date,
  endDateFrom: EventType.filters_end_date,
  endDateTo: EventType.filters_end_date,
  company: EventType.filters_company,
  source: EventType.filters_source,
  category: EventType.filters_category,
  status: EventType.filters_status
}

function trackEvent(
  eventType: EventType, 
  eventProperties?: Record<string, string | number | boolean>, 
  userProperties?: Record<string, string | number | boolean>,
) {
  const event: BaseEvent = {
    event_type: eventType
  }

  if (eventProperties) {
    event.event_properties = eventProperties
  }

  if (userProperties) {
    event.user_properties = userProperties
  }

  amplitude.track(event);
}

export { EventType, trackEvent, filterEvents }

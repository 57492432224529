import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "language-overlay",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleLanguageDropdown && _ctx.toggleLanguageDropdown(...args)))
          }))
        : _createCommentVNode("v-if", true)
    ])),
    _createElementVNode("div", {
      class: "language-dropdown js-language-list",
      style: _normalizeStyle({ display: _ctx.show ? 'block' : 'none' })
    }, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.chooseLanguage(_ctx.Language.uz))),
        class: _normalizeClass({ 'is-selected': _ctx.activeLanguage === _ctx.Language.uz })
      }, "Ўзбекча", 2 /* CLASS */),
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.chooseLanguage(_ctx.Language.ru))),
        class: _normalizeClass({ 'is-selected': _ctx.activeLanguage === _ctx.Language.ru })
      }, "Русский", 2 /* CLASS */),
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.chooseLanguage(_ctx.Language.en))),
        class: _normalizeClass({ 'is-selected': _ctx.activeLanguage === _ctx.Language.en })
      }, "English", 2 /* CLASS */)
    ], 4 /* STYLE */)
  ], 64 /* STABLE_FRAGMENT */))
}
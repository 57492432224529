import { ICompany, IPaginatedCompanies } from "@/interfaces/api/companyClient";
import { ICompanyState, IRootState } from "@/interfaces/state";
import { ActionContext, Module } from "vuex";
import companyClient from "@/api/companyClient";


const COMPANIES_LIMIT = 30


const companyModule: Module<ICompanyState, IRootState> = {
  state(): ICompanyState {
    return {
      company: null,
      queryCompany: '',
      companies: {
        data: [] as ICompany[],
        max_page: 0,
        total: 0,
      }
    };
  },
  getters: {
    company(state: ICompanyState) {
      return state.company
    },
    queryCompany(state: ICompanyState) {
      return state.queryCompany
    },
    companies(state: ICompanyState) {
      return state.companies
    }
  },
  mutations: {
    setCompany(state: ICompanyState, payload: { company: ICompany }) {
      state.company = payload.company
    },
    setQueryCompany(state: ICompanyState, payload: { query: string }) {
      state.queryCompany = payload.query
    },
    setCompanies(state: ICompanyState, payload: { companies: IPaginatedCompanies }) {
      state.companies = payload.companies
    }
  },
  actions: {
    async getCompany(
      context: ActionContext<ICompanyState, ICompanyState>, 
      payload: { company_tin: string }
    ) {
      const company = await companyClient.getCompany(payload.company_tin)

      if (company == null) {
        context.commit('setCompany', { company: null })
        return null
      }

      context.commit('setCompany', { company: company } )
    },
    setQueryCompany(context, payload: { query: string }) {
      context.commit("setQueryCompany", payload)
    },
    async getCompanies(
      context: ActionContext<ICompanyState, ICompanyState>,
      payload: { page: number },
    ) {
      const offset = (payload.page - 1) * COMPANIES_LIMIT;

      context.commit('setIsLoading', { isLoading: true })
      const companies = await companyClient.getCompanies(COMPANIES_LIMIT, offset)
      context.commit("setCompanies", { companies: companies });
      context.commit('setIsLoading', { isLoading: false })
    }
  }
}

export default companyModule

import store from '@/store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { nextTick } from 'vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/ProfileView.vue')
  },
  {
    path: '/procedures/:tenderId',
    name: 'tenderPage',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/TenderView.vue')
  },
  {
    path: '/user_agreement',
    name: 'userAgreement',
    component: () => import('../views/UserAgreementView.vue')
  },
  {
    path: '/privacy_and_terms',
    name: 'privacyAndTerms',
    component: () => import('../views/PrivacyAndTermsView.vue')
  },
  {
    path: '/privacy_and_terms_app',
    name: 'privacyAndTermsApp',
    component: () => import('../views/PrivacyAndTermsAppView.vue')
  },
  {
    path: '/procedures',
    name: 'tenderSerp',
    component: () => import('../views/SearchResultsView.vue')
  },
  {
    path: '/procedures/source/:source',
    name: 'sourceSerp',
    component: () => import('../views/SearchResultsView.vue')
  },
  {
    path: '/procedures/region/:region',
    name: 'regionSerp',
    component: () => import('../views/SearchResultsView.vue')
  },
  {
    path: '/procedures/type/:type',
    name: 'typeSerp',
    component: () => import('../views/SearchResultsView.vue')
  },
  {
    path: '/procedures/category/:category',
    name: 'categorySerp',
    component: () => import('../views/SearchResultsView.vue')
  },
  {
    path: '/favourites',
    name: 'favourites',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/FavouritesView.vue')
  },
  {
    path: '/templates',
    name: 'templates',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/SearchTemplatesView.vue')
  },
  {
    path: '/company/:companyTin',
    name: 'company',
    component: () => import('../views/CompanyView.vue')
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('../views/MapCollectionsView.vue')
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('../views/MapCompaniesView.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404View.vue')
  },
  { path: "/:notFound", redirect: '/404' },
]

if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/ngrams',
    name: 'ngramSerp',
    component: () => import('../views/NGramSearchResultsView.vue')
  });
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(function(to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    store.commit('setRedirected', true);
    store.commit('setIntendedRoute', to.fullPath);
    next('/')
  } else if (to.name == 'home' && store.getters.isAuthenticated) {
    next('/procedures?page=1')
  } else {
    next()
  }
})

router.afterEach((to) => {
  if (to.path === '/' && !store.getters.isAuthenticated && store.getters.redirected) {
    nextTick(() => store.dispatch('toggleAuthModal'));
    store.commit('setRedirected', false)
  }
});

export default router
